<template>
  <div class="products">
    <Breadcrumb :items="breadcrumb" />
    <v-container class="pt-0 pt-sm-3">
      <ProposalSlider :proposals="headerBanner" />
      <!-- <v-container> -->
      <CategoryTitle v-if="category" :category="category" />
      <!-- </v-container> -->
      <v-row class="py-2">
        <v-col
          v-for="subCategory in category.children"
          :key="subCategory.id"
          cols="6"
          sm="6"
          md="3"
        >
          <v-card
            class="
              proposal-card
              d-flex
              flex-column
              align-center
            "
            rounded="sm"
            flat
            :to="{ name: 'Category', params: { pathMatch: subCategory.slug } }"
          >
            <img
              :src="
                subCategory.metaData.category_info.THUMB
                  ? subCategory.metaData.category_info.THUMB
                  : `${publicPath}imgs/no-image-grey.png`
              "
              :alt="subCategory.name"
              width="180"
              height="180"
            />
            <v-card-text
              class="pre-slide-text align-center"
              v-html="subCategory.name"
            ></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div
      class="product-slider-wrapper"
      v-for="proposal in footerProducts"
      :key="proposal.id"
    >
      <ProductListSlider
        :proposal="proposal"
        :promo="true"
        :categoryId="category.categoryId"
        :title="'In promozione in ' + category.name"
        :paginationClass="'swiper-pagination' + proposal.id"
        :key="key"
      />
    </div>

    <!-- <div class="secondary product-slider-wrapper">
      <ProductListSlider
        :proposal="footerProducts"
        :layout="25"
        :categoryId="category.categoryId"
        :promo="true"
        :title="'In promozione in ' + category.name"
        :paginationClass="'swiper-pagination-promo'"
        class="secondary"
      />
    </div> -->
    <div class="grey lighten-2 product-slider-wrapper">
      <ProductListSlider
        :layout="27"
        :categoryId="category.categoryId"
        :title="'I più venduti in ' + category.name"
        :paginationClass="'swiper-pagination-venduti'"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.proposal-card {
  background: transparent;
}
.pre-slide-text {
  width: initial;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: $primary !important;
}
</style>
<script>
// @ is an alias to /src

import ProductListSlider from "@/components/product/ProductListSlider";
import Breadcrumb from "@/components/navigation/Breadcrumb";
import CategoryTitle from "@/components/category/CategoryTitle";
import ProposalSlider from "@/components/proposal/ProposalSlider";
import categoryMixins from "~/mixins/category";
import { mapProposal } from "~/service/ebsn.js";

import get from "lodash/get";

export default {
  name: "CategoryMain",
  mixins: [categoryMixins],
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        pagination: {
          el: ".firstCategory",

          clickable: true
        }
      }
    };
  },
  components: {
    ProductListSlider,
    Breadcrumb,
    CategoryTitle,
    ProposalSlider
  },
  computed: {
    ...mapProposal({
      headerBanner: "header-banner",
      footerProducts: "footer-products"
    })
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category ? this.category.name : ""
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.$t("meta.category.firstLevel.description", [
              this.category.name
            ])
          )
        }
      ]
    };
  }
};
</script>
